import IIMSApplication from 'domains/trustedApps/models/IIMSApplication'
import IIMSApplicationVetting from 'domains/trustedApps/models/IIMSApplicationVetting'

export const TRUSTED_APPS_NOTES_DISPLAY_MAP = {
  technicalNotes: 'Technical Notes',
  instructionalNotes: 'Instructional Notes',
}

export interface TrustedAppRating {
  ratingId: number
  ratingText: string
  iconIdentifier: string
  iconColor: string
  isEmbedded: boolean
}

export default interface ITrustedApplication {
  id: number
  applicationId: number // the same as id
  logo: string
  title: string
  orgName: string
  certified: boolean
  vetted: boolean
  sealed?: boolean
  technicalNotes: string
  instructionalNotes: string
  coreOrSupplemental?: string
  subjects?: string[]
  tooluses?: string[]
  grades?: string[]
  reviwedByInstructionalDesigners?: boolean
  rosteringMethod?: string
  gradePassbackMethod?: string
  accessMethods?: string[]
  securityReview?: string
  learningStandardsAlignment?: string
  downstreamAnalytics?: string
  dataSharing?: string
  licensingCoverage: string[]
  licensingExpirationDate?: number
  licensingFees?: string
  rating?: TrustedAppRating
}

export interface IFullTrustedApplication extends ITrustedApplication {
  application: IIMSApplication
}

export interface ICustomTrustedApplication
  extends Omit<ITrustedApplication, 'id' | 'logo'> {
  vetting?: IIMSApplicationVetting
  applicationUrl?: string
  description?: string
}

export const hasAdditionalInfo = (app?: ITrustedApplication): boolean => {
  const values = [
    app?.technicalNotes,
    app?.instructionalNotes,
    app?.coreOrSupplemental,
    app?.subjects,
    app?.tooluses,
    app?.grades,
    app?.reviwedByInstructionalDesigners,
    app?.rosteringMethod,
    app?.gradePassbackMethod,
    app?.accessMethods,
    app?.securityReview,
    app?.learningStandardsAlignment,
    app?.licensingCoverage,
    app?.licensingFees,
  ]
  const nonNullValues = values.filter((val) => {
    if (Array.isArray(val)) {
      return val.length > 0
    }

    return ![undefined, null].includes(val as any)
  })

  return nonNullValues.length > 0
}
