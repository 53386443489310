import React from 'react'
import { Formik, FormikHelpers } from 'formik'

import { ModalWrapper, SearchBox } from 'lib/components'
import FormSelect from 'lib/components/modern/Select/FormSelect'
import useTrustedAppsAdditionalInfo from 'domains/trustedApps/hooks/useTrustedAppsAdditionalInfo'
import trackTrustedAppsAnalytics from 'domains/trustedApps/utils/trackTrustedAppsAnalytics'
import useTrustedAppsSupplierOptions from 'domains/trustedApps/hooks/useTrustedAppsSupplierOptions'
import useTrustedAppsTooluses from 'domains/trustedApps/hooks/useTrustedAppsTooluses'
import { getVocab } from 'lib/constants/vocab'
import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { TRUSTED_APPS_RATINGS_RECORD } from 'lib/records/modules/trustedAppsRatings'
import { TrustedAppRating } from '../models/ITrustedApplication'

export type TrustedAppsAdvancedSearchFilter = {
  rating: string[]
  orgName: string[]
  coreOrSupplemental: string | null
  subjects: string[]
  tooluses: string[]
  grades: string[]
  reviwedByInstructionalDesigners: boolean | null
  rosteringMethod: string | null
  gradePassbackMethod: string | null
  accessMethods: string[]
  securityReview: string | null
  learningStandardsAlignment: string | null
  downstreamAnalytics: string | null
  dataSharing: string | null
  licensingCoverage: string[]
  licensingExpirationDate: number | null
  licensingFees?: string | null
}
export const DEFAULT_TA_FILTER_STATE: TrustedAppsAdvancedSearchFilter = {
  rating: [],
  orgName: [],
  coreOrSupplemental: null,
  subjects: [],
  tooluses: [],
  grades: [],
  reviwedByInstructionalDesigners: null,
  rosteringMethod: null,
  gradePassbackMethod: null,
  accessMethods: [],
  securityReview: null,
  learningStandardsAlignment: null,
  downstreamAnalytics: null,
  dataSharing: null,
  licensingCoverage: [],
  licensingExpirationDate: null,
  licensingFees: null,
}

export const getCurrentTrustedAppAdvancedSearchFilters = (
  filter: TrustedAppsAdvancedSearchFilter,
): any[] =>
  Object.keys(filter)
    .map((key) => {
      if (key === 'dataSharing') return filter[key] ? filter[key] : null
      return filter[key as keyof TrustedAppsAdvancedSearchFilter]
    })
    .filter((f) => {
      return Array.isArray(f) ? f.length : f !== null && f !== undefined
    })

export const getTrustedAppAdvancedSearchFilterCount = (
  filter: TrustedAppsAdvancedSearchFilter,
): number =>
  getCurrentTrustedAppAdvancedSearchFilters(filter).reduce(
    (sum, f) => sum + (Array.isArray(f) ? f.length : 1),
    0,
  )

const TRACK_EVENT_ACTION = 'advanced_search'

type Props = {
  query: string
  filter: TrustedAppsAdvancedSearchFilter
  isOpen: boolean
  onRequestClose: () => void
  onSearch: (query: string, filters: TrustedAppsAdvancedSearchFilter) => any
}

export default function TrustedAppsAdvancedSearchModal(props: Props) {
  const [query, setQuery] = React.useState(props.query)
  React.useEffect(() => {
    if (props.isOpen) setQuery(props.query)
  }, [props.isOpen, props.query])

  const [tools = [], toolsPending] = useTrustedAppsTooluses()
  const [additionInfoOptions, optionsPending] = useTrustedAppsAdditionalInfo()

  const [
    initialSupplierOptions,
    supplierOptions,
    resetSupplierOptions,
    getSupplierOptions,
  ] = useTrustedAppsSupplierOptions()

  const onSubmit = async (
    values: TrustedAppsAdvancedSearchFilter,
    bag: FormikHelpers<TrustedAppsAdvancedSearchFilter>,
  ) => {
    trackTrustedAppsAnalytics(TRACK_EVENT_ACTION, values)
    props.onSearch(query, values)
    props.onRequestClose()
    bag.setSubmitting(false)
  }

  const [ratingsResponse] = useRecordEntity({
    record: TRUSTED_APPS_RATINGS_RECORD,
    id: 1,
    noReFetch: true,
  })

  const ratings: TrustedAppRating[] | undefined = ratingsResponse?.ratings

  const pending = optionsPending || toolsPending

  return (
    <Formik initialValues={props.filter} onSubmit={onSubmit} enableReinitialize>
      {(formProps) => {
        const activeFilterCount = getTrustedAppAdvancedSearchFilterCount(
          formProps.values,
        )

        const onClearAll = () => {
          setQuery('')
          formProps.setValues(DEFAULT_TA_FILTER_STATE)
        }
        return (
          <ModalWrapper
            isOpen={props.isOpen}
            title="Filter"
            actions={
              pending
                ? []
                : [
                    {
                      text: 'Search',
                      variant: 'start',
                      extra: { type: 'submit' },
                      onClick: formProps.submitForm,
                    },
                    ...(activeFilterCount > 0
                      ? [
                          {
                            text: `Clear All (${activeFilterCount})`,
                            variant: 'gray-400',
                            onClick: onClearAll,
                          },
                        ]
                      : []),
                    {
                      text: 'Cancel',
                      variant: 'neutral',
                      onClick: () => {
                        formProps.resetForm()
                        props.onRequestClose()
                      },
                    },
                  ]
            }
            pending={formProps.isSubmitting}
            maxWidth={['100%', 820, 900]}
          >
            <div className="flex flex-col space-y-3 bg-white pb-16">
              <SearchBox
                name="search"
                // trackEvent={trackAnalytics}
                // trackEventAction={`${trackAnalyticsCategory}-search`}
                onChange={setQuery}
                value={query}
                placeholder="Search..."
                width="100%"
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col space-y-3">
                  <FormSelect
                    label="Rating"
                    name="rating"
                    selected={formProps.values.rating}
                    onChange={(value: any) =>
                      formProps.setFieldValue('rating', value)
                    }
                    placeholder="Select"
                    options={
                      ratings?.map((r) => ({
                        label: r.ratingText,
                        value: r.ratingId,
                      })) || []
                    }
                    multiple
                  />
                  <FormSelect
                    label="Supplier"
                    name="orgName"
                    selected={formProps.values.orgName}
                    onChange={(value: any) => {
                      formProps.setFieldValue('orgName', value)
                      resetSupplierOptions()
                    }}
                    placeholder="Select"
                    options={supplierOptions}
                    initialOptions={initialSupplierOptions}
                    onSearchChange={getSupplierOptions}
                    multiple
                  />
                  <FormSelect
                    label="Core vs Supplemental"
                    name="coreOrSupplemental"
                    selected={formProps.values.coreOrSupplemental}
                    onChange={(value: any) =>
                      formProps.setFieldValue('coreOrSupplemental', value)
                    }
                    placeholder="Select One"
                    options={additionInfoOptions.coreOrSupplemental.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.coreOrSupplemental.multiple}
                    extensible={
                      additionInfoOptions.coreOrSupplemental.extensible
                    }
                  />
                  <FormSelect
                    label={getVocab('subject', true, true)}
                    name="subjects"
                    selected={formProps.values.subjects}
                    onChange={(value: any) =>
                      formProps.setFieldValue('subjects', value)
                    }
                    placeholder="Select"
                    options={additionInfoOptions.subjects.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.subjects.multiple}
                    extensible={additionInfoOptions.subjects.extensible}
                  />
                  <FormSelect
                    label={getVocab('toolUse', true, false)}
                    name="tooluses"
                    selected={formProps.values.tooluses}
                    onChange={(value: any) =>
                      formProps.setFieldValue('tooluses', value)
                    }
                    placeholder="Select"
                    options={[
                      ...additionInfoOptions.tooluses.adminValues.map((s) => ({
                        label: s,
                        value: s,
                      })),
                      ...tools.map((s) => ({
                        label: s,
                        value: s,
                      })),
                    ]}
                    multiple={additionInfoOptions.tooluses.multiple}
                    extensible={additionInfoOptions.tooluses.extensible}
                  />
                  <FormSelect
                    label={getVocab('grade', true, true)}
                    name="grades"
                    selected={formProps.values.grades}
                    onChange={(value: any) =>
                      formProps.setFieldValue('grades', value)
                    }
                    placeholder="Select"
                    options={additionInfoOptions.grades.adminValues.map(
                      (s) => ({
                        label: s,
                        value: s,
                      }),
                    )}
                    multiple={additionInfoOptions.grades.multiple}
                    extensible={additionInfoOptions.grades.extensible}
                  />
                  <FormSelect
                    label={`Has app been reviewed by instructional designers in the ${getVocab(
                      'district',
                    )}?`}
                    name="reviwedByInstructionalDesigners"
                    selected={formProps.values.reviwedByInstructionalDesigners}
                    onChange={(value: any) => {
                      formProps.setFieldValue(
                        'reviwedByInstructionalDesigners',
                        value,
                      )
                    }}
                    placeholder="Select One"
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                  />
                </div>

                <div className="flex flex-col space-y-3">
                  <FormSelect
                    label={`How is this ${getVocab('rostered')}?`}
                    name="rosteringMethod"
                    selected={formProps.values.rosteringMethod}
                    onChange={(value: any) =>
                      formProps.setFieldValue('rosteringMethod', value)
                    }
                    placeholder="Select One"
                    options={additionInfoOptions.rosteringMethod.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.rosteringMethod.multiple}
                    extensible={additionInfoOptions.rosteringMethod.extensible}
                  />
                  <FormSelect
                    label="Does this send Grade data back?"
                    name="gradePassbackMethod"
                    selected={formProps.values.gradePassbackMethod}
                    onChange={(value: any) =>
                      formProps.setFieldValue('gradePassbackMethod', value)
                    }
                    placeholder="Select One"
                    options={additionInfoOptions.gradePassbackMethod.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.gradePassbackMethod.multiple}
                    extensible={
                      additionInfoOptions.gradePassbackMethod.extensible
                    }
                  />
                  <FormSelect
                    label="How do students and faculty access the app?"
                    name="accessMethods"
                    selected={formProps.values.accessMethods}
                    onChange={(value: any) =>
                      formProps.setFieldValue('accessMethods', value)
                    }
                    placeholder="Select One"
                    options={additionInfoOptions.accessMethods.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.accessMethods.multiple}
                    extensible={additionInfoOptions.accessMethods.extensible}
                  />
                  <FormSelect
                    label="Has institution performed security review?"
                    name="securityReview"
                    selected={formProps.values.securityReview}
                    onChange={(value: any) =>
                      formProps.setFieldValue('securityReview', value)
                    }
                    placeholder="Select One"
                    options={additionInfoOptions.securityReview.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.securityReview.multiple}
                    extensible={additionInfoOptions.securityReview.extensible}
                  />
                  <FormSelect
                    label="Does this app align activities to an academic standard?"
                    name="learningStandardsAlignment"
                    selected={formProps.values.learningStandardsAlignment}
                    onChange={(value: any) =>
                      formProps.setFieldValue(
                        'learningStandardsAlignment',
                        value,
                      )
                    }
                    placeholder="Select One"
                    options={additionInfoOptions.learningStandardsAlignment.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={
                      additionInfoOptions.learningStandardsAlignment.multiple
                    }
                    extensible={
                      additionInfoOptions.learningStandardsAlignment.extensible
                    }
                  />
                  <FormSelect
                    label="Does this send app data for downstream learning analytics?"
                    name="downstreamAnalytics"
                    selected={formProps.values.downstreamAnalytics}
                    onChange={(value: any) =>
                      formProps.setFieldValue('downstreamAnalytics', value)
                    }
                    placeholder="Select One"
                    options={additionInfoOptions.downstreamAnalytics.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.downstreamAnalytics.multiple}
                    extensible={
                      additionInfoOptions.downstreamAnalytics.extensible
                    }
                  />
                  <FormSelect
                    label="Is there a data sharing agreement on file between the institution and vendor?"
                    name="dataSharing"
                    selected={formProps.values.dataSharing}
                    onChange={(value: any) => {
                      formProps.setFieldValue('dataSharing', value)
                    }}
                    placeholder={`Select${
                      additionInfoOptions.dataSharing.multiple ? '' : ' one'
                    }${
                      additionInfoOptions.dataSharing.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={additionInfoOptions.dataSharing.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.dataSharing.multiple}
                    extensible={additionInfoOptions.dataSharing.extensible}
                  />
                  <FormSelect
                    label="Licensing Coverage"
                    name="licensingCoverage"
                    selected={formProps.values.licensingCoverage}
                    onChange={(value: any) => {
                      formProps.setFieldValue('licensingCoverage', value)
                    }}
                    placeholder="Select or add your own"
                    options={additionInfoOptions.grades.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={true}
                    extensible={true}
                  />
                </div>
              </div>
            </div>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
